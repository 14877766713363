import React from "react";
import { Route } from "react-router-dom";

const Accesos = React.lazy(() => import("./accesos/accesos/accesos"));

const UserList = React.lazy(() => import("./accesos/usuarios/userList"));

const UserFormRoute = React.lazy(() => import("./accesos/usuarios/UserFormRoute"));

const RoleList = React.lazy(() => import("./accesos/permisos/roleList"));

const RoleFormRoute = React.lazy(() => import("./accesos/permisos/RoleFormRoute"));

const SectorList = React.lazy(() => import("./accesos/sectores/sectorList"));

const SectorFormRoute = React.lazy(() => import("./accesos/sectores/SectorFormRoute"));

const Error403 = React.lazy(() => import("./errors/403"));


export default [
  <Route path="/usuarios" key={0}>
    <Route index element={<UserList />} key={0}/>
    <Route path="add" element={<UserFormRoute />} key={1}/>
    <Route path=":id/" element={<UserFormRoute />} key={2}/>
    <Route path=":id/:view" element={<UserFormRoute />} key={3}/>
  </Route>,

  <Route path="/permisos" key={1}>
    <Route index element={<RoleList />} />
    <Route path="add" element={<RoleFormRoute />} />
    <Route path=":id/" element={<RoleFormRoute />} />
    <Route path=":id/:view" element={<RoleFormRoute />} />
  </Route>,

  <Route path={"/accesos"} key={2}>
    <Route index element={<Accesos />} />
  </Route>,

  <Route path="/sectores" key={3}>
    <Route index element={<SectorList />} />
    <Route path="add" element={<SectorFormRoute />} />
    <Route path=":id/" element={<SectorFormRoute />} />
    <Route path=":id/:view" element={<SectorFormRoute />} /> 
  </Route>,

<Route path={"/error-403"} key={4}>
<Route index element={<Error403 />} />
</Route>,
];
