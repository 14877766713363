
import React from "react";
import { Route } from "react-router-dom";
const ObjetivosDelDiaAdmin = React.lazy(() =>
  import("./cobranza/objetivosDelDia/ObjetivosDelDiaAdmin")
);

const ObjetivosDelDiaListAdmin = React.lazy(() =>
    import("./cobranza/objetivosDelDia/ObjetivosDelDiaListAdmin")
  );

const ObjetivosDelDiaList = React.lazy(() =>
    import("./cobranza/objetivosDelDia/ObjetivosDelDiaList")
  );

  const DetalleCliente = React.lazy(() =>
    import("./cobranza/objetivosDelDia/DetalleCliente")
  );

export default [
  <Route path="/objetivos-del-dia-admin" key={0}>
    <Route index element={<ObjetivosDelDiaAdmin />} />
    <Route path=":id/" element={<ObjetivosDelDiaListAdmin isAdmin={true}/>} /> 
  </Route>,

  <Route path="/objetivos-del-dia" key={1}>
    <Route index element={<ObjetivosDelDiaList isAdmin={false} />} />
    <Route path=":id/" element={<DetalleCliente />} />
  </Route>,
];
