export default {
 // baseURL: "https://localhost:44376",
 baseURL: 'https://2.apalapa.net/FrontEndApi',
  productionURL: "https://2.apalapa.net/FrontEndApi",
  version: "1.23.5",
  sistema: "1",
  locale: {
    locale: "es",
    name: "Spanish",
    icon: "es",
    googleMapsApiKey: "AIzaSyAVuddcpI18nKCGjclB_m1yYf6J6YTDWxM",
    node: "16.20.2",
  },
  centerMapDefault: {
    lat: -34.6262583,
    lng: -58.6964373,
  },
  CopyrightText: "© All Rights Reversed | Developed by www.cretai.com",
};
