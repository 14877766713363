import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import VpnKey from "@mui/icons-material/VpnKey";
import Refresh from "@mui/icons-material/Refresh";
import Alert from "@mui/lab/Alert";
import appConfig from "./../../constants/appConfig";
import { UserService } from "../../services/systemService/userService";
import Logo from "../../assets/images/logoRenoavado.svg";
import LoadingButton from "../../components/common/buttons/LoadingButton";

import  { userInfo, screenInfo, cookiesEnabled, connectionInfo } from "utils/useInfo";
// import useAxiosPost from "../../services/api/useAxiosPost";

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  // const { data, loading, error, makePostRequest } = useAxiosPost('/api/Auth/Login');

  const onSubmit = async (e) => {
    var postData = {
      email: email,
      password: password,
    };

    setIsLogin(true);

    UserService.login(postData)
      .then((response) => {
        props.login(response.data);
      })
      .catch((error) => {
        setMessage("Datos incorrectos." + error);
        setIsLogin(false);
      });
    e.preventDefault();
  };

  return (
    <Container maxWidth="xs" style={{ height: "88vh" }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <ValidatorForm
            onSubmit={onSubmit}
            onError={(errors) => console.log(errors)}
          >
            <div style={{ marginTop: "14vh" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <center>
                    <img src={Logo} alt="Grupo West" width="125px" />
                  </center>
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    label="Usuario"
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                    value={email}
                    size="normal"
                    variant="outlined"
                    fullWidth
                    autoFocus
                    validators={["required"]}
                    errorMessages={["El usuario es requerido"]}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextValidator
                    label="Contraseña"
                    type="password"
                    variant="outlined"
                    size="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    name="password"
                    fullWidth
                    validators={["required"]}
                    errorMessages={["La contraseña es requerida"]}
                  />
                </Grid>

                <Grid item xs={10}>
                  <LoadingButton
                    icon={<VpnKey />}
                    isLoading={isLogin}
                    text="Ingresar"
                    loadingText="Ingresando"
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    variant="text"
                    value="Refresh Page"                    
                    onClick={() => window.location.reload(true)}
                  >
                    <Refresh />
                  </IconButton>
                </Grid>
                <Grid item xs={10}>
                  {message ? (
                    <Alert severity="error" className="mt-15 mb-30">
                      Ups, algo ocurrió. {message}{" "}
                    </Alert>
                  ) : null}{" "}
                </Grid>

                <Grid item xs={12}>
                  <Grid container alignItems={"center"}>
                    <Grid item xs={12}>
                      <br />

                      <Typography align="center" style={{ color: "#888" }}>
                        Version {appConfig.version}
                      </Typography>
{/* 
                      <p>{userInfo.language}</p>
                      <p>{userInfo.timeZone}</p>
                      <p>{userInfo.userAgent }</p>

                      <p>{screenInfo.width + "|" + screenInfo.height + "|" + screenInfo.colorDepth }</p>

                      <p>{connectionInfo.type + "|" + connectionInfo.effectiveType + "|" + connectionInfo.downlink }</p> */}


                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </ValidatorForm>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Login;
